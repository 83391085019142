<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleftsmall: w.right }">
      <div class="contentleft-inner">
        <div class="contentleft-header">
          <div class="mr5" @click="CreatePeed()">
            <b-button icon-left="plus" type="is-info">New </b-button>
          </div>
          <div class="taskSearchSel">
            <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();doSearch();" expanded>
              <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
            </b-select>
          </div>
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk lgreybg p11 m11" v-if="peedList.length === 0">No PEED value proposition profiles created</div>
          <div class="tsk p7 m7 isPointer isFlex isFlexCol tskshadow dcard" :class="{ dcardopen: w.right }" v-for="(n, index) in peedList" :key="index">
            <div class="txtc mt11 mb11" @click="openPeed(n, index)">
              <font-awesome-icon :icon="['fal', 'id-card']" size="2x" v-if="n.type === 'Profile'"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'box']" size="2x" v-if="n.type === 'Product'"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'question']" size="2x" v-if="n.type === 'Other'"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'concierge-bell']" size="2x" v-if="n.type === 'Service'"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'newspaper']" size="2x" v-if="n.type === 'Content'"></font-awesome-icon>
              <font-awesome-icon :icon="['fal', 'bullhorn']" size="2x" v-if="n.type === 'Advert'"></font-awesome-icon>
            </div>
            <div class="isFlexGrow" @click="openPeed(n, index)">
              <div class="txtc txtbold mb3 mt5">{{ n.title }}</div>
              <div class="txtsm txtc"><span v-if="n.goaltitle !== ''" :style="`color: ${n.goalcolor}`">{{n.goaltitle}}</span><span v-else>Not associated with a goal</span></div>
              <div class="txtc txtsm mb3 mt5">{{ n.oneliner }}</div>
            </div>
            <div class="txtc mb3 mt5"><b-checkbox size="is-small" v-model="n.checked">Compare</b-checkbox></div>
          </div>
        </div>

        <div class="p5 isFlex">
          <div class="isFlexGrow mr11">
            <b-pagination
              @change="getPeeds($event)"
              :total="total"
              :current="current"
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :order="order"
              :size="size"
              :simple="isSimple"
              :rounded="isRounded"
              :per-page="perPage"
              :icon-prev="prevIcon"
              :icon-next="nextIcon"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
          </div>
          <div><b-button expanded @click="comparePeed">Compare</b-button></div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
          </div>
          <div v-if="ActivePeed.canEdit">
            <div v-if="!isEdit">
              <div class="iconbutton mv5" @click="isEdit=true">
                <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div v-if="isEdit">
              <div class="iconbutton mv5" @click="isEdit=false">
                <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="iconbutton mv11 bluetxt11" @click="peedSave()" v-if="isEdit">
              <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv11 red" @click="peedDelete()" v-if="isEdit">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="contentright-body ">
          <div class="contentright-body-body scrolly">
            
            <div class="tile">
              <div class="tile is-5 p11 is-vertical">
                <div class="txth4 mb11">Info</div>

                <template>
                  <!-- general -->
                  <div class="p7 lgreybg isFlex isPointer" @click="(sectiongeneral) ? sectiongeneral=false:sectiongeneral=true" v-if="isEdit">
                    <div class="isFlexGrow">General</div>
                    <div class="iconbutton">
                      <font-awesome-icon :icon="['fal', (sectiongeneral ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                    </div>
                  </div>
                  <template v-if="sectiongeneral || !isEdit">
                    <!-- attach to goal: start -->
                    <div class="pt9 mb5" v-if="isEdit">
                      <div class="field" v-if="isadmin"><b-switch v-model="ActivePeed.demo">Demo</b-switch></div>
                      <div class="txtbold mb3">Associated with goal...</div>
                      <b-select placeholder="Select Goal" expanded v-model="ActivePeed.goalid" @input="changeGoal">
                        <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
                      </b-select>
                    </div>
                    <div v-if="!isEdit" class="txtsm mb5">Type: {{ActivePeed.type}}</div>
                    <div v-if="!isEdit" class="txtsm mb5">Goal: <span v-if="ActivePeed.goaltitle !== ''">{{ActivePeed.goaltitle}}</span><span v-else>Not associated with a goal</span></div>
                    <!-- type: start -->
                    <div class="mt5 mb5" v-if="isEdit">
                      <div class="txtbold mb3">Type</div>
                      <b-select placeholder="" v-model="ActivePeed.type" expanded><option v-for="o in peedtypes" :value="o.value" :key="o.value">{{ o.label }}</option></b-select>
                    </div>
                    <div style="height: 15px;"></div>
                  </template>

                  <!-- Profile Identification -->
                  <div class="p7 lgreybg isFlex isPointer" @click="(sectionprogen) ? sectionprogen=false:sectionprogen=true" v-if="isEdit">
                    <div class="isFlexGrow">{{ActivePeed.type}} Identification</div>
                    <div class="iconbutton">
                      <font-awesome-icon :icon="['fal', (sectionprogen ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                    </div>
                  </div>
                  <template v-if="sectionprogen || !isEdit">
                    <!-- Title: start -->
                    <div class="mt5 mb5" v-if="isEdit">
                      <div class="txtbold mb3">Give your {{ActivePeed.type}} a name...</div>
                      <b-input v-model="ActivePeed.title" placeholder="Please enter a title"></b-input>
                    </div>
                    <div v-if="!isEdit" class="txth5 txtbold mb5">{{ActivePeed.title}}</div>
                    <!-- Oneliner: start -->
                    <div class="mt5 mb5" v-if="isEdit">
                      <div class="txtbold mb3">Short description that will appear in the listings.</div>
                      <b-input v-model="ActivePeed.oneliner" placeholder="Customer profile"></b-input>
                    </div>
                    <div v-if="!isEdit" class="mb5">{{ActivePeed.oneliner}}</div>
                    <!-- description -->
                    <div class="mt5 mb11" v-if="isEdit">
                      <div class="txtbold mb3">Description</div>
                      <b-input v-model="ActivePeed.description" placeholder="Please enter a title" type="textarea" rows="3"></b-input>
                    </div>
                    <div v-if="!isEdit && ActivePeed.description !== ''" class="mb5">{{ActivePeed.description}}</div>
                    <!-- age -->
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="mt5 mb11" v-if="isEdit">
                        <div class="isFlex">
                          <div style="width: 50%" class="txtbold mb3">Age From</div>
                          <div style="width: 50%" class="txtbold mb3">Age To</div>
                        </div>
                        <div class="isFlex">
                          <div style="width: 50%" class="pr5"><b-numberinput min="0" v-model="ActivePeed.agestart"></b-numberinput></div>
                          <div style="width: 50%" class="pl5"><b-numberinput min="0" v-model="ActivePeed.agestop"></b-numberinput></div>
                        </div>
                      </div>
                      <pillbox label="Age" v-if="!isEdit">
                        <span>{{ActivePeed.agestart}}</span>
                        <span v-if="ActivePeed.agestop !== 0"> - {{ActivePeed.agestop}}</span>
                      </pillbox>
                    </template>
                    
                    <!-- gender -->
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="mt5 mb5" v-if="isEdit">
                        <div class="txtbold mb3">Gender</div>
                        <b-select placeholder="" v-model="ActivePeed.gendertxt" expanded><option v-for="o in peedgender" :value="o.value" :key="o.value">{{ o.label }}</option></b-select>
                      </div>
                      <pillbox label="Gender" v-if="!isEdit">{{ActivePeed.gendertxt}}</pillbox>
                    </template>
                    
                    <!-- sexual orientation -->
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Sexual Orientation</div>
                        <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.sexualorientation)" v-if="isEdit">
                          <option v-for="(o, si)  in peedsexual" :value="o" :key="si">{{ o.label }}</option>
                        </b-select>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 lgreybg m3" v-if="ActivePeed.sexualorientation.length === 0">Nothing selected</div>
                        <draggable tag="div" :list="ActivePeed.sexualorientation" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.sexualorientation" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.sexualorientation)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </template>

                    <!-- ethnicity -->
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Ethnicity</div>
                        <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.ethnicity)" v-if="isEdit">
                          <option v-for="(o, si)  in peedethnicity" :value="o" :key="si">{{ o.label }}</option>
                        </b-select>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.ethnicity.length === 0">Nothing selected</div>
                        <draggable tag="div" :list="ActivePeed.ethnicity" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.ethnicity" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.ethnicity)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </template>
                    <!-- ethnicity: ends -->
                    <!-- features: start -->
                    <template>
                      <div class="mt5 mb5">
                        <template v-if="ActivePeed.type === 'Profile'">
                          <div class="txtbold mb3">Appearance</div>
                          <div class="txt mb3" v-if="isEdit">Define what this profile looks like. Eye colour, hair length, body shape, how they dress, their style</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.features)" v-if="isEdit">
                            <option v-for="(o, si) in peedfeaturesprofile" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                          <b-input placeholder="Winners aren't phased by appearance."
                              v-model="textfeatures" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textfeatures', ActivePeed.features)" v-if="isEdit">
                          </b-input>
                        </template>
                        <template v-if="ActivePeed.type !== 'Profile'">
                          <div class="txtbold mb3">Features</div>
                          <div class="txt mb3" v-if="isEdit">List all the features of the {{ActivePeed.type}} you are offering.</div>
                          <b-input placeholder="Winners care more for benefits than features."
                              v-model="textfeatures" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textfeatures', ActivePeed.features)" v-if="isEdit">
                          </b-input>
                        </template>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk lgreybg m3" v-if="ActivePeed.features.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.features" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.features" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.features)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </template>
                    <!-- features: end -->

                    <!-- dress: start -->
                    <template>
                      <div class="mt5 mb5">
                        <template v-if="ActivePeed.type === 'Profile'">
                          <div class="txtbold mb3">Their style</div>
                          <div class="txt mb3" v-if="isEdit">What is their style?</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.dress)" v-if="isEdit">
                            <option v-for="(o, si) in peeddress" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                          <b-input placeholder="Winners have their own style."
                              v-model="textdress" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textdress', ActivePeed.dress)" v-if="isEdit">
                          </b-input>
                        </template>
                        <template v-if="ActivePeed.type !== 'Profile'">
                          <div class="txtbold mb3">Benefits</div>
                          <div class="txt mb3" v-if="isEdit">List the benefits of your {{ActivePeed.type}}</div>
                          <b-input placeholder="Benefits sell, features bore."
                              v-model="textdress" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textdress', ActivePeed.dress)" v-if="isEdit">
                          </b-input>
                        </template>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.dress.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.dress" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.dress" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.dress)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </template>
                    <!-- dress: end -->

                    <div style="height: 15px;"></div>
                  </template>
                  
                  <!-- Profile Accommodation -->
                  <template v-if="ActivePeed.type !== 'Other'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectionlocation) ? sectionlocation=false:sectionlocation=true" v-if="isEdit">
                      <div class="isFlexGrow">Where?</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectionlocation ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectionlocation || !isEdit">
                      <!-- Geo location -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3" v-if="ActivePeed.type === 'Profile'">Where do they live?</div>
                          <div class="txtbold mb3" v-if="ActivePeed.type === 'Product' || ActivePeed.type === 'Service'">Where will you be selling this {{ActivePeed.type}}?</div>
                          <div class="txtbold mb3" v-if="ActivePeed.type === 'Content' || ActivePeed.type === 'Advert'">Where will you be publishing this {{ActivePeed.type}}?</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.locations)" v-if="isEdit">
                            <option v-for="(o, si)  in peedlocations" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 lgreybg m3" v-if="ActivePeed.locations.length === 0">Nothing selected</div>
                          <draggable tag="div" :list="ActivePeed.locations" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.locations" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.locations)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- accomodation -->
                      <template v-if="ActivePeed.type === 'Profile'">
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Accomodation</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.accomodation)" v-if="isEdit">
                            <option v-for="(o, si)  in peedaccomodation" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.accomodation.length === 0">Nothing selected</div>
                          <draggable tag="div" :list="ActivePeed.accomodation" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.accomodation" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.accomodation)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      
                      <div style="height: 15px;"></div>
                    </template>
                  </template>

                  <!-- Profile Education -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectioncareer) ? sectioncareer=false:sectioncareer=true" v-if="isEdit">
                      <div class="isFlexGrow">Career &amp; Education</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectioncareer ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectioncareer || !isEdit">
                      <!-- Education -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Education</div>
                        <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.education)" v-if="isEdit">
                          <option v-for="(o, si) in peededucation" :value="o" :key="si">{{ o.label }}</option>
                        </b-select>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.education.length === 0">Nothing selected</div>
                        <draggable tag="div" :list="ActivePeed.education" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.education" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.education)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                      <!-- employment -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Employment</div>
                        <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.employment)" v-if="isEdit">
                          <option v-for="(o, si)  in peedemployment" :value="o" :key="si">{{ o.label }}</option>
                        </b-select>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.employment.length === 0">Nothing selected</div>
                        <draggable tag="div" :list="ActivePeed.employment" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.employment" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.employment)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                      <!-- Job Role -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Profession</div>
                        <div class="txt mb3" v-if="isEdit">Describe what the person does for a living. e.g. Accountant, Banker, Factory worker, React Developer</div>
                        <b-input placeholder="Chicken plucker"
                            v-model="textjob"
                            icon-right="plus"
                            icon-right-clickable
                            @icon-right-click="addText('textjob', ActivePeed.jobrole)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.jobrole.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.jobrole" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.jobrole" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.jobrole)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                      <div style="height: 15px;"></div>
                    </template>
                  </template>
                  

                  <!-- Routine -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectionlikes) ? sectionlikes=false:sectionlikes=true" v-if="isEdit">
                      <div class="isFlexGrow">Routine</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectionlikes ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectionlikes || !isEdit">
                      <!-- Morning: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Morning routine</div>
                        <div class="txt mb3" v-if="isEdit">What does this profile do in the morning? e.g. Wakes up at 5am, Checks phone first thing, Leaves for work at 8am</div>
                        <!-- <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.morning)">
                          <option v-for="(o, si)  in peedmorning" :value="o" :key="si">{{ o.label }}</option>
                        </b-select> -->
                        <b-input placeholder="Winners get up at 5am!"
                            v-model="textmorning"
                            icon-right="plus"
                            icon-right-clickable
                            @icon-right-click="addText('textmorning', ActivePeed.morning)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.morning.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.morning" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.morning" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.morning)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      <!-- Morning: end -->
                      <!-- Midday: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Midday routine</div>
                        <div class="txt mb3" v-if="isEdit">What does this profile do around noon? e.g. Has lunch at 1pm, Checks twitter before leaving office, Skips lunch</div>
                        <b-input placeholder="Winners take a short break!"
                            v-model="textmidday" icon-right="plus" icon-right-clickable
                            @icon-right-click="addText('textmidday', ActivePeed.midday)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.midday.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.midday" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.midday" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.midday)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      <!-- Midday: end -->
                      <!-- Afternoon: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Afternoon routine</div>
                        <div class="txt mb3" v-if="isEdit">What does this profile in the afternoon? e.g. Goes for a nap, Walks the dog, Picks up kids, Daily standup at 3pm, Leaves work at 5pm</div>
                        <b-input placeholder="Winners give it all in the afternoon"
                            v-model="textafternoon" icon-right="plus" icon-right-clickable
                            @icon-right-click="addText('textafternoon', ActivePeed.afternoon)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.afternoon.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.afternoon" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.afternoon" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.afternoon)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      <!-- Afternoon: end -->
                      <!-- evening: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Evening routine</div>
                        <div class="txt mb3" v-if="isEdit">What does this profile in the evening? e.g. Watches Days of our lives, TicTok!, Makes dinner for the family, Goes to bed at 11pm</div>
                        <b-input placeholder="Winners don't watch rubbish TV!"
                            v-model="textevening" icon-right="plus" icon-right-clickable
                            @icon-right-click="addText('textevening', ActivePeed.evening)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.evening.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.evening" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.evening" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.evening)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      <!-- evening: end -->
                      <div style="height: 15px;"></div>
                    </template>
                  </template>
                  

                  <!-- Consumption -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectionconsumption) ? sectionconsumption=false:sectionconsumption=true" v-if="isEdit">
                      <div class="isFlexGrow">Consumption</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectionconsumption ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectionconsumption || !isEdit">
                      <!-- brandrate: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Brand importance</div>
                        <b-select placeholder="Please select" expanded v-model="ActivePeed.brandrate" v-if="isEdit">
                          <option v-for="(o, si) in peedbrandrate" :value="o.value" :key="si">{{ o.label }}</option>
                        </b-select>
                      </div>
                      <!-- brandrate: end -->
                      <!-- brands: start -->
                      <div class="mt5 mb5">
                        <div class="txtbold mb3">Brands</div>
                        <div class="txt mb3" v-if="isEdit">List the brands the user likes. e.g. Tom Ford, DNE, Mercedes, Louis Vuitton, Simba</div>
                        <b-input placeholder="Winners shop sensibly!"
                            v-model="textbrand" icon-right="plus" icon-right-clickable
                            @icon-right-click="addText('textbrand', ActivePeed.brands)" v-if="isEdit">
                        </b-input>
                      </div>
                      <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                        <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.brands.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.brands" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.brands" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.brands)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      <!-- brands: end -->
                      <!-- shopping: start -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Buying habits</div>
                          <div class="txt mb3" v-if="isEdit">Describe this profiles buying habits.</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.shopping)" v-if="isEdit">
                            <option v-for="(o, si)  in peedshopping" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                          <b-input placeholder="Winners support local businesses!"
                              v-model="textshopping" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textshopping', ActivePeed.shopping)" v-if="isEdit">
                          </b-input>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.shopping.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="ActivePeed.shopping" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.shopping" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.shopping)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- shopping: end -->

                      <div style="height: 15px;"></div>
                    </template>
                  </template>
                  
                  <!-- Online presence and habits -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectionmedia) ? sectionmedia=false:sectionmedia=true" v-if="isEdit">
                      <div class="isFlexGrow">Online presence and habits</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectionmedia ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectionmedia || !isEdit">
                      <!-- media: start -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Regulars</div>
                          <div class="txt mb3" v-if="isEdit">What does this profile like to read or watch. Not social media platforms. e.g. The Daily Show, Eastenders, iCarly, CNN. This can also be a person that they follow. e.g. Melinda Gates, Trevor Noah, Daniel Priestly, Ray Noppe, You.</div>
                          <b-select placeholder="Select to add..." expanded @input="arrayAdd($event, ActivePeed.media)" v-if="isEdit">
                            <option v-for="(o, si) in peedmediaoptions" :value="o" :key="si">{{ o.label }}</option>
                          </b-select>
                          <b-input placeholder="Winners don't waist time watching crap TV"
                              v-model="textmedia" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('textmedia', ActivePeed.media)" v-if="isEdit">
                          </b-input>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.media.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="ActivePeed.media" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.media" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.media)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- media: end -->
                      <!-- platforms: start -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Social media</div>
                          <div class="txt mb3" v-if="isEdit">What is this profile's social media presence like.</div>
                          <b-select placeholder="Select to add..." expanded v-model="socialselect" v-if="isEdit">
                            <option v-for="(o, si) in peedsocial" :value="o.value" :key="si">{{ o.label }}</option>
                          </b-select>
                          <b-input placeholder="Myspace" v-model="socialtext" v-if="socialselect === 'other'"></b-input>
                          <template v-if="socialselect !== ''">
                            <div class="isFlex mt7">
                              <div class="w50p pr5"><b-field label="Followers From"><b-input v-model="followersfrom" type="number"></b-input></b-field></div>
                              <div class="w50p pl5"><b-field label="Followers To"><b-input v-model="followersto" type="number"></b-input></b-field></div>
                            </div>
                            <div class="isFlex mt7">
                              <div class="w50p pr5"><b-field label="Following From"><b-input v-model="followingfrom" type="number"></b-input></b-field></div>
                              <div class="w50p pl5"><b-field label="Following To"><b-input v-model="followingto" type="number"></b-input></b-field></div>
                            </div>
                            <div class="isFlex mt7">
                              <div class="w50p pr5"><b-field label="Posts From"><b-input v-model="postfrom" type="number"></b-input></b-field></div>
                              <div class="w50p pl5"><b-field label="Posts To"><b-input v-model="poststo" type="number"></b-input></b-field></div>
                            </div>
                            <div class="mt7">
                              <b-field label="Usage patterns"><b-input v-model="socialusage" placeholder="e.g. Mornings, 8.45am - 9.15am"></b-input></b-field>
                            </div>
                            <div class="mt7">
                              <b-checkbox v-model="influencer">Is an Influencer</b-checkbox>
                            </div>
                            <div class="buttons mb11" v-if="!socialedit">
                              <b-button expanded @click="addSocial">Add</b-button>
                            </div>
                            <div class="buttons mb11" v-if="socialedit">
                              <b-button expanded @click="editDone">Update</b-button>
                            </div>
                          </template>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.platforms.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="ActivePeed.platforms" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.platforms" :key="ti">
                              <div class="isFlexGrow">
                                <div>{{t.platform}}</div>

                                <div class="txtsm" v-if="t.followersfrom !== 0 && t.followersto !== 0">Has between {{t.followersfrom}} and {{t.followersto}} followers</div>
                                <div class="txtsm" v-if="t.followersfrom !== 0 && t.followersto === 0">Has more than {{t.followersfrom}} followers</div>
                                <div class="txtsm" v-if="t.followersfrom === 0 && t.followersto !== 0">Has less than {{t.followersto}} followers</div>

                                <div class="txtsm" v-if="t.followingfrom !== 0 && t.followingto !== 0">Follows between {{t.followingfrom}} and {{t.followingto}} profiles</div>
                                <div class="txtsm" v-if="t.followingfrom !== 0 && t.followingto === 0">Follows more than {{t.followingfrom}} profiles</div>
                                <div class="txtsm" v-if="t.followingfrom === 0 && t.followingto !== 0">Follows less than {{t.followingto}} profiles</div>

                                <div class="txtsm" v-if="t.postfrom !== 0 && t.poststo !== 0">Has between {{t.postfrom}} and {{t.poststo}} posts</div>
                                <div class="txtsm" v-if="t.postfrom !== 0 && t.poststo === 0">Has more than {{t.postfrom}} posts</div>
                                <div class="txtsm" v-if="t.postfrom === 0 && t.poststo !== 0">Has less than {{t.poststo}} posts</div>

                                <div class="txtsm" v-if="t.influencer">Influencer</div>

                                <div class="txtsm" v-if="t.socialusage">{{t.socialusage}}</div>
                                
                              </div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="editSocial(t)"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.platforms)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- platforms: end -->

                      <div style="height: 15px;"></div>
                    </template>
                  </template>

                  <!-- Offline hangouts and habits: start -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="p7 lgreybg isFlex isPointer" @click="(sectionhabits) ? sectionhabits=false:sectionhabits=true" v-if="isEdit">
                      <div class="isFlexGrow">Real life hangouts and habits</div>
                      <div class="iconbutton">
                        <font-awesome-icon :icon="['fal', (sectionhabits ? 'caret-down':'caret-right')]" size="lg" ></font-awesome-icon>
                      </div>
                    </div>
                    <template v-if="sectionhabits || !isEdit">
                      <!-- hangouts: start -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Hangouts</div>
                          <div class="txt mb3" v-if="isEdit">Where do they hangout and go in real life? e.g. Local coffee shop, Village Pub, Restaurants, Networking events</div>
                          <b-input placeholder="Winners go to networking events!"
                              v-model="texthangout" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('texthangout', ActivePeed.hangouts)" v-if="isEdit">
                          </b-input>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.hangouts.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="ActivePeed.hangouts" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.hangouts" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.hangouts)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- hangouts: end -->

                      <!-- habits: start -->
                      <template>
                        <div class="mt5 mb5">
                          <div class="txtbold mb3">Habits</div>
                          <div class="txt mb3" v-if="isEdit">What is this profiles habits. e.g. Cycles once a week, Flies drones, Attends fashion shows</div>
                          <b-input placeholder="I got nothing?"
                              v-model="texthabits" icon-right="plus" icon-right-clickable
                              @icon-right-click="addText('texthabits', ActivePeed.habits)" v-if="isEdit">
                          </b-input>
                        </div>
                        <div v-bind:class="{ 'tsk lgreyborder p3': isEdit }">
                          <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.habits.length === 0">Nothing entered</div>
                          <draggable tag="div" :list="ActivePeed.habits" handle=".handle">
                            <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.habits" :key="ti">
                              <div class="isFlexGrow">{{t.text}}</div>
                              <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                                <div class="iconbutton" @click="removeFrom(ti, ActivePeed.habits)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                                <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                              </div>
                            </div>
                          </draggable>
                        </div>
                      </template>
                      <!-- habits: end -->

                      <div style="height: 15px;"></div>
                    </template>
                  </template>
                  <!-- Offline hangouts and habits: end -->

                  <template v-if="sectioncareer">

                    <div style="height: 15px;"></div>
                  </template>
                  



                  <div style="height: 30px;"></div>
                </template>

              </div>


              <div class="tile is-7 p11 is-vertical">
                <div class="txth4 mb11">PEED</div>
                <div class="txt mb3">This is where we determine your value proposition in regards to the {{ActivePeed.type}}</div>

                <!-- painpoints: start -->
                <div class="mb11">
                  <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                    <div class="isFlexGrow">Painpoints</div>
                    <div class="iconbutton" @click="(helppain) ? helppain=false:helppain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                  </div>

                  <div class="lgreybg p11" v-if="helppain">What pain points are you fixing? What pain points are they moving aways from?<br/>This is serious issues not nice to haves. e.g. "Have to lose weight to prevent a heart attack" vs a desire "Want to lose weight to look good in a bikini or mankini." </div>
                  
                  <div class="lgreybg roundedbottom p7 isFlex">

                    <div style="width: 50%">
                      <div class="txtbold">Me</div>

                      <b-input placeholder="What pain points are you fixing for them?"
                        v-model="textpainpoints" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textpainpoints', ActivePeed.painpoints)" v-if="isEdit" @keyup.native.enter="addText('textpainpoints', ActivePeed.painpoints);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.painpoints.length === 0">No pain points defined</div>
                        <draggable tag="div" :list="ActivePeed.painpoints" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.painpoints" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.painpoints)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                    </div>

                    <div style="width: 50%">
                      <div class="txtbold">Them</div>
                      <b-input placeholder="What pain points are they moving away from?"
                        v-model="textpainpointsthem" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textpainpointsthem', ActivePeed.painpointsthem)" v-if="isEdit" @keyup.native.enter="addText('textpainpointsthem', ActivePeed.painpointsthem);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.painpointsthem.length === 0">No pain points defined</div>
                        <draggable tag="div" :list="ActivePeed.painpointsthem" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.painpointsthem" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.painpointsthem)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </div>


                
                <!-- painpoints: end -->

                <!-- educate: start -->
                <div class="mb11">
                  <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                    <div class="isFlexGrow">Educate</div>
                    <div class="iconbutton" @click="(helpeducate) ? helpeducate=false:helpeducate=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                  </div>

                  <div class="lgreybg p11" v-if="helpeducate">Does your offer educate? 
                      <br/>What do they have to learn to use your offer? <br/>What can you teach them? <br/>What do they want to learn?</div>

                  <div class="lgreybg roundedbottom p7 isFlex">
                    
                    <div style="width: 50%">
                      <div class="txtbold">Me</div>

                      <b-input placeholder="What do they need to learn?"
                        v-model="texteducate" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('texteducate', ActivePeed.educate)" v-if="isEdit" @keyup.native.enter="addText('texteducate', ActivePeed.educate);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.educate.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.educate" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.educate" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.educate)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      
                    </div>
                    <div style="width: 50%">
                      <div class="txtbold">Them</div>

                      <b-input placeholder="What do they want to learn?"
                        v-model="texteducatethem" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('texteducatethem', ActivePeed.educatethem)" v-if="isEdit" @keyup.native.enter="addText('texteducatethem', ActivePeed.educatethem);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.educatethem.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.educatethem" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.educatethem" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.educatethem)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                    </div>
                    
                  </div>
                </div>
                <!-- educate: end -->

                <!-- entertain: start -->
                <div class="mb11">
                  <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                    <div class="isFlexGrow">Entertain</div>
                    <div class="iconbutton" @click="(helpentertain) ? helpentertain=false:helpentertain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                  </div>

                  <div class="lgreybg p11" v-if="helpentertain">How does this offering entertain them. e.g. If you have a maths tutor in the form of a game. Playing the game will entertain them.</div>
                  
                  <div class="lgreybg roundedbottom p7 isFlex">

                    <div style="width: 50%">
                      <div class="txtbold">Me</div>

                      <b-input placeholder="How can you entertain them?"
                        v-model="textentertain" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textentertain', ActivePeed.entertain)" v-if="isEdit" @keyup.native.enter="addText('textentertain', ActivePeed.entertain);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.entertain.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.entertain" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.entertain" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.entertain)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                    </div>
                    <div style="width: 50%">
                      <div class="txtbold">Them</div>

                      <b-input placeholder="Do they want to be entertained and how?"
                        v-model="textentertainthem" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textentertainthem', ActivePeed.entertainthem)" v-if="isEdit" @keyup.native.enter="addText('textentertainthem', ActivePeed.entertainthem);">
                      </b-input>
                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.entertainthem.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.entertainthem" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.entertainthem" :key="ti">
                            <div class="isFlexGrow">{{t.text}}</div>
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.entertainthem)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                    </div>
                  

                  </div>
                </div>
                <!-- entertain: end -->

                <!-- desire: start -->
                <div class="mb11">
                  <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                    <div class="isFlexGrow">Desire</div>
                    <div class="iconbutton" @click="(helpdesire) ? helpdesire=false:helpdesire=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                  </div>

                  <div class="lgreybg p11" v-if="helpdesire">What is this profile's desires or what desire can your offering envoke in them. This is not the same as painpoints. These are nice to haves. <br/>"If they buy my offering they will look cooler", <br/>"I want to lose 10 pounds to fit into a cocktail dress"</div>

                  <div class="lgreybg roundedbottom p7 isFlex">

                    <div style="width: 50%">
                      <div class="txtbold">Me</div>

                      <b-input placeholder="How can you envoke desire in them?"
                        v-model="textdesire" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textdesire', ActivePeed.desire)" v-if="isEdit" @keyup.native.enter="addText('textdesire', ActivePeed.desire);">
                      </b-input>

                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.desire.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.desire" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.desire" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.desire)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>
                      
                    </div>
                    <div style="width: 50%">
                      <div class="txtbold">Them</div>

                      <b-input placeholder="What do they desire?"
                        v-model="textdesirethem" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textdesirethem', ActivePeed.desirethem)" v-if="isEdit" @keyup.native.enter="addText('textdesirethem', ActivePeed.desirethem);">
                      </b-input>

                      <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                        <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.desirethem.length === 0">Nothing entered</div>
                        <draggable tag="div" :list="ActivePeed.desirethem" handle=".handle">
                          <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.desirethem" :key="ti">
                            <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                            <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                              <div class="iconbutton" @click="removeFrom(ti, ActivePeed.desirethem)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                              <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                            </div>
                          </div>
                        </draggable>
                      </div>

                    </div>

                  </div>
                </div>
                <!-- desire: end -->

                <!-- blockers: start -->
                <div class="mb11">
                  <div class="txtbold redbg white roundedtop p7 isFlex">
                    <div class="isFlexGrow">Blockers</div>
                    <div class="iconbutton" @click="(helpblockers) ? helpblockers=false:helpblockers=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                  </div>
                  <div class="lgreybg roundedbottom p7">
                    <div class="mt7 mb11" v-if="helpblockers">What are the main blockers you will experience? 
                      <br/>What obstacles do you have to overcome in order for this profile to buy/follow/read/sell what you have to offer?
                      <br/>e.g. "They have to be educated on how to use the app",
                      <br/>"We need them to change a habit"
                      <br/>"They can't afford it"
                      </div>
                    <b-input placeholder="What prevents a decission?"
                        v-model="textblockers" icon-right="plus" icon-right-clickable
                        @icon-right-click="addText('textblockers', ActivePeed.blockers)" v-if="isEdit" @keyup.native.enter="addText('textblockers', ActivePeed.blockers);">
                    </b-input>
                    <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                      <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.blockers.length === 0">Nothing entered</div>
                      <draggable tag="div" :list="ActivePeed.blockers" handle=".handle">
                        <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.blockers" :key="ti">
                          <contenteditable tag="div" class="isFlexGrow" :contenteditable="isEdit" v-model="t.text" :noNL="true" />
                          <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                            <div class="iconbutton" @click="removeFrom(ti, ActivePeed.blockers)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                            <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                          </div>
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>
                <!-- blockers: end -->
              </div>
            </div>

            <div>
              
            </div>

          </div>
          <div class="p11 bluebg11" v-if="ActivePeed.type === 'Chat' || ActivePeed.type === 'Request'">
            <div><b-input v-model="newReply" maxlength="500" rows="2" type="textarea" placeholder="Post a reply"></b-input></div>
            <div class="buttons">
              <b-button expanded @click="postReply">Post Reply</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import { Schemas } from "@/schemas/all.js";
import array from "lodash/array";
import debounce from "lodash/debounce";
import { windowSplit2 } from "../mixins/windowSplit.js";
import draggable from "vuedraggable";
import ModalPeedCompare from '../modals/ModalPeedCompare.vue';

Vue.component("pillbox", {
  template: "pillbox-template",
  props: {
    label: String,
    // value: undefined,
    width: { type: String, default: '100px' },
  },
  data: function () {
    return {
      lb: this.label,
      // vl: this.value,
      st: `width: ${this.width};`,
    }
  },
  template: `<div class="mb5 isFlex isFlexAlign"><div class="roundedleft lgreybg p5 pl11" style="width: 100px;">{{lb}}</div><div class="p5 isFlexGrow vlgreybg roundedright"><span><slot></slot></span></div></div>`});

export default {
  mixins: [Utils, windowSplit2, Schemas],
  components: {
    draggable
  },
  name: 'PEED',
  data() {
    return {
      editor: null,
      peedList: [],
      peedCompList: [],
      peopleList: [],
      searchField: '',
      ActivePeed: {},
      ActivePeedId: -1,
      ActiveRow: {},
      isEdit: false,
      showEditor: false,
      canEdit: false,
      isNew: false,

      sectiongeneral: true,
      sectionprogen: false,
      sectionlocation: false,
      sectioncareer: false,
      sectionlikes: false,
      sectionmedia: false,
      sectionconsumption: false,
      sectionhabits: false,

      helppain: false,
      helpeducate: false,
      helpentertain: false,
      helpdesire: false,
      helpblockers: false,

      newNotify: {
        to: [],
        subject: '',
        message: '',
        type: 'Chat',
        goalid: '',
        goaltitle: '',
        goalcolor: '',
      },
      isLoading: true,
      newReply: '',
      GoalList: [],
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      perPage: 20,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      // pagination: end

      textjob: '',
      textmorning: '',
      textmidday: '',
      textafternoon: '',
      textevening: '',
      textbrand: '',
      textshopping: '',
      textfeatures: '',
      textdress: '',
      textmedia: '',
      texthangout: '',
      texthabits: '',

      textpainpoints: '',
      texteducate: '',
      textentertain: '',
      textdesire: '',
      textblockers: '',

      textpainpointsthem: '',
      texteducatethem: '',
      textentertainthem: '',
      textdesirethem: '',
      textblockersthem: '',

      isEditable: true,

      socialselect: '',
      socialtext: '',
      influencer: false,
      followersfrom: 0,
      followersto: 0,
      followingfrom: 0,
      followingto: 0,
      postfrom: 0,
      poststo: 0,
      socialusage: '',
      socialedit: false,
      isadmin: localStorage.getItem("isadmin"),
    }
  },
  created() {
    this.getGoalSelect();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getPeeds(0);
    // this.getGoals();
  },
  methods: {
    
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.current = 1;
      this.total = 0;
      this.getPeeds(0);
    }, 2000),

    async getPeeds(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        goalid: this.ActiveGoalId,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/peeds/listcount', sendData, 'rows', false);
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const gnotify = await this.DataSend('post', '/peeds', sendData, 'rows', false);
      
      this.peedList = gnotify;
      for (const p of this.peedList) {
        p.checked = false;
        p.cntpain = p.painpoints.length;
        p.cntedu = p.educate.length;
        p.cntent = p.entertain.length;
        p.cntdes = p.desire.length;
        p.cntblock = p.blockers.length;
      }
      this.isLoading = false;
    },

    async openPeed(row, index) {
      this.ActivePeedId = index;
      this.editor = null;
      this.showEditor = false;
      this.isLoading = true;
      this.isNew = false;
      const gc = await this.DataGet(`/peed/${row._id}`);

      this.ActivePeed = gc;
      this.openRightAll();
      this.isLoading = false;
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.ActivePeed.goalid = data;
        this.ActivePeed.goaltitle = this.GoalList[gindex].goaltitle;
        this.ActivePeed.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.ActivePeed.goalid = '';
        this.ActivePeed.goaltitle = '';
        this.ActivePeed.goalcolor = '';
      }
    },

    async CreatePeed() {
      const newpeed = await this.DataGet('/peednew');
      this.peedList.unshift(newpeed);
      this.openPeed(newpeed, 0);
    },

    async peedSave() {
      const dosave = await this.DataSend('put', '/peed', this.ActivePeed, 'rows', true);
      this.peedList[this.ActivePeedId].title = dosave.title;
      this.peedList[this.ActivePeedId].type = dosave.type;
      this.peedList[this.ActivePeedId].oneliner = dosave.oneliner;
    },

    async peedDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this peed? This cannot be undone.",
        confirmText: "Delete peed",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.closeRight();
          const delpeed = await this.DataDelete(`/peed/${this.ActivePeed._id}`, "row");
          this.peedList.splice(this.ActivePeedId, 1);
        }
      });
    },

    arrayAdd(data, usearr) {
      if (data.value === '') { return; }
      if (data.value === 'custom') {
        this.arrayAddCustom(usearr);
        return;
      }
      usearr.push({ text: data.value });
    },
    arrayAddCustom(usearr) {
      this.$buefy.dialog.prompt({
          message: `Add a custom option...`,
          inputAttrs: {
              placeholder: 'e.g. Whatever',
              maxlength: 50,
          },
          confirmText: 'Add',
          onConfirm: (value) => {
            usearr.push({ text: value });
          }
      })
    },
    
    addText(field, usearr) {
      if (this[field] === '') {
        return;
      }
      usearr.push({ text: this[field] });
      this[field] = '';
    },

    addSocial() {
      if (this.socialselect === '') { return; }
      let useplatform = this.socialselect;
      if (this.socialselect === 'other') { useplatform = this.socialtext; }
      const nr = {
        platform: useplatform,
        influencer: this.influencer,
        followersfrom: this.followersfrom * 1,
        followersto: this.followersto * 1,
        followingfrom: this.followersfrom * 1,
        followingto: this.followersto * 1,
        postfrom: this.postfrom * 1,
        poststo: this.poststo * 1,
        socialusage: this.socialusage
      }

      this.ActivePeed.platforms.push(nr);

      this.socialselect = '';
      this.socialtext = '';
      this.influencer = false;
      this.followersfrom = 0;
      this.followersto = 0;
      this.followingfrom = 0;
      this.followingto = 0;
      this.postfrom = 0;
      this.poststo = 0;
      this.socialusage = '';
    },

    editSocial(row) {
      this.ActiveRow = row;
      this.socialedit = true;
      this.socialselect = 'other';
      this.socialtext = row.platform;
      this.influencer = row.influencer;
      this.followersfrom = row.followersfrom;
      this.followersto = row.followersto;
      this.followingfrom = row.followingfrom;
      this.followingto = row.followingto;
      this.postfrom = row.followingto;
      this.poststo = row.poststo;
      this.socialusage = row.socialusage;
    },

    editDone() {
     
        this.ActiveRow.platform = this.socialtext;
        this.ActiveRow.influencer = this.influencer;
        this.ActiveRow.followersfrom = this.followersfrom;
        this.ActiveRow.followersto = this.followersto;
        this.ActiveRow.followingfrom = this.followingfrom;
        this.ActiveRow.followingto = this.followingto;
        this.ActiveRow.postfrom = this.postfrom;
        this.ActiveRow.poststo = this.poststo;
        this.ActiveRow.socialusage = this.socialusage;
        
      this.socialedit = false;
      this.socialselect = '';
      this.socialtext = '';
      this.influencer = false;
      this.followersfrom = 0;
      this.followersto = 0;
      this.followingfrom = 0;
      this.followingto = 0;
      this.postfrom = 0;
      this.poststo = 0;
      this.socialusage = '';
    },

    // remove
    removeFrom(index, usearr) {
      usearr.splice(index, 1);
    },

    comparePeed() {

      const compareArr = [];
      for (const p of this.peedList) {
        if (p.checked) {
          compareArr.push(p);
        }
      }
      if (compareArr.length === 0) {
        this.$buefy.snackbar.open({ message: "Please make an selection first", position: "is-bottom" });
        return;
      }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalPeedCompare,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: compareArr,
          inEdit: this.canEdit,
          inDemo: this.isDemo,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },
  },
  computed: {
    
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.ProseMirror {
  /* border: 1px solid #CCC; */
  min-height: 300px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>
